import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { Alert, Divider, Checkbox, Row, Col, Radio, Image } from 'antd'
import QRCode from "qrcode.react";
import axios from 'axios'

import { useInterval } from 'usehooks-ts'
import alipay from "./images/alipay.svg";
import wechat from "./images/wechat.svg"


const Charge = () => {

    const [qrUrl, setQrUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [paidResult, setPaidResult] = useState("");

    const [order, setOrder] = useState("")
    const [paid, setPaid] = useState(false)
    const [isChecking, setIsChecking] = useState(false);

    const [paymentMethod, setPaymentMethod] = useState("alipay");
    const [wechatImage, setWechatImage] = useState("");

    const [ackknowledge, setAckknowledge] = useState(false);

    useInterval(
        () => {
            // Your custom logic here
            queryOrderStatus(order)
        },
        // Delay in milliseconds or null to stop it
        isChecking ? 5000 : null,
    )

    const onFinish = (values) => {
        //console.log('Success:', values);
        if (['1376217864', '874632695'].includes(values.qq)) { //黑名单用户,淘宝给了差评的2个用户
            setPaidResult("该Q号不支持充值!");
            return;
        }
        submitOrder(values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const submitOrder = (values) => {
        //console.log("submit order")
        setLoading(true);
        setQrUrl("");
        var url = new URLSearchParams(values).toString();
        var payment_method = values['payment_method']
        setPaymentMethod(payment_method);
        //setWechatImage(wechat_image_selection[values['charge_type']])
        //setPaidResult("请打开微信扫描二维码完成支付");
        axios.get(`/api/junqi/chong?${url}&ts=${new Date().getTime()}`)
            .then(response => {
                if (payment_method === 'alipay') {
                    if (response.data.msg === 'Success') {
                        setQrUrl(response.data.qr_code);
                        setPaidResult("请打开支付宝扫描二维码完成支付, 为避免充值出错, 请务必在3分钟内完成支付!");
                        setOrder(response.data.order)
                        setIsChecking(true);
                    } else {
                        console.log("What happen", response);
                        setLoading(false);
                    }
                } else { //wechat
                    //check if channel is available
                    let available_payment_channel = response.data.available_payment_channel
                    if (available_payment_channel !== -1) {
                        setWechatImage(`./images/wechat/wechat_${values['charge_type']}_channel_${available_payment_channel}.png`)
                        setPaidResult("请打开微信扫描二维码完成支付, 请务必在3分钟内完成支付!");
                        setOrder(response.data.order)
                        setIsChecking(true);
                    } else {
                        setWechatImage("");
                        setPaidResult("微信支付通道均被占用, 为避免充值出错, 请稍等3分钟刷新重试!");
                        setLoading(false);
                    }
                }

            })
            .catch((error) => {
                console.log(error)
            })

    }

    const queryOrderStatus = async (order) => {
        // then the created Promise can be awaited

        setPaidResult("正在查询支付状态，请不要关闭当前页面!")
        axios.get(`/api/junqi/query?order=${order}&payment_method=${paymentMethod}&ts=${new Date().getTime()}`)
            .then(response => {
                let status = response.data.status;
                let new_expire_time = response.data.new_expire_time;
                setPaid(status)
                if (status === 'paid') {
                    setPaidResult(`已完成支付! 感谢支持! 新的到期时间为: ${new_expire_time}`)
                    setIsChecking(false);
                    setLoading(false);
                    setQrUrl("");
                    setWechatImage("");
                } else if (status === 'cancel') {
                    setPaidResult("订单超时未支付，已取消! 如有需要请重新下单!")
                    setQrUrl("");
                    setWechatImage("");
                    setIsChecking(false);
                    setLoading(false);
                }
            }
            )
            .catch((error) => {
                console.log(error)
                return error
            })
    }

    return (<>

        <p>
            <Alert
                message="特别提示："
                description={<ul>
                    <li>虚拟商品，充值后不支持退款！ </li>
                    <li>充值完成后对应QQ号自动获得相应时长! 您无需在软件端做充值操作!</li>
                </ul>
                }
                type="warning"
                showIcon
            />
        </p>
        <Divider orientation="left"><b>自助充值</b></Divider>
        <Row gutter={16}>
            <Col span={12}>
                <Form
                    name="basic"
                    labelWrap
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="请输入QQ号"
                        name="qq"
                        rules={[
                            {
                                required: true,
                                pattern: new RegExp(/^[1-9]\d*$/, "g"),
                                message: '请填写你玩游戏的Q号(仅限数字)!',
                            },
                        ]}
                    >
                        <Input
                            placeholder={"请输入QQ号码，不要输错了哦~"}
                            disabled={loading} />
                    </Form.Item>
                    <Form.Item
                        name="charge_type"
                        label="请选择时长"
                        rules={[
                            {
                                required: true,
                                message: '好歹选个要充值的时长呀!',
                            },
                        ]}
                    >
                        <Radio.Group disabled={loading} buttonStyle="solid">
                            <Radio.Button value="day"> 1天(￥2 ) </Radio.Button>
                            <Radio.Button value="week"> 1周(￥7 ) </Radio.Button>
                            <Radio.Button value="month"> 1月(￥18 ) </Radio.Button>
                            <Radio.Button value="year"> 1年(￥158 ) </Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="payment_method"
                        label="请选择支付方式"
                        rules={[
                            {
                                required: true,
                                message: '好歹选个支付方式吧!',
                            },
                        ]}
                    >
                        <Radio.Group disabled={loading}>
                            <Radio value="wechat">
                                <Image
                                    width={100}
                                    preview={false}
                                    src={wechat}
                                />
                            </Radio>
                            <Radio value="alipay">
                                <Image
                                    width={80}
                                    preview={false}
                                    src={alipay}
                                />
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Checkbox onChange={() =>
                            setAckknowledge(!ackknowledge)}>我了解当前腾讯调整防作弊房间数据, 辅助效果比以前部分降低的现状</Checkbox>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <>

                            <Button type="primary" htmlType="submit" disabled={loading || !ackknowledge}>
                                确定
                            </Button>
                        </>
                    </Form.Item>
                </Form>

            </Col>
            <Col span={12}>
                {paidResult && <Alert message={paidResult} type={paid === 'paid' ? "success" : paid === 'cancel' ? "error" : "info"} />}
                {qrUrl &&
                    <QRCode
                        value={qrUrl} //value参数为生成二维码的链接
                        size={200} //二维码的宽高尺寸
                        //fgColor="#000"     //"#1678ff"//二维码的颜色
                        imageSettings={{
                            src: "https://img.alicdn.com/tfs/TB1qEwuzrj1gK0jSZFOXXc7GpXa-32-32.ico",
                            x: undefined,
                            y: undefined,
                            height: 24,
                            width: 24,
                            excavate: true,
                        }}
                    />
                }
                {
                    wechatImage &&
                    <Image
                        width={200}
                        preview={false}
                        src={wechatImage}
                    />
                }
            </Col>
        </Row>

    </>

    )
}

export default Charge